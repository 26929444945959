import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import propTypes, {object} from 'prop-types'
import {Paragraph, StyledTitle} from "../../../../components/ui/typography/index.js";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import Basket from "../../basket/index.js";
import {useEvent} from "../../../events/js/hooks/index.js";
import {Divider} from "../../../../components/ui/Divider.jsx";
import {useProduct} from "../hooks/index.js";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {convertToCurrency} from "../../../../js/helpers/helpers.js";
import {useApiQuery} from "../../../../hooks/customHooks.js";
import {IoAddSharp, IoInformationCircleSharp, IoTrashBinSharp} from "react-icons/io5";
import {useHandleRemoveOrderItem} from "../../js/hooks/index.js";
import {AddonProduct, Addons, LinkedCatalogue} from "./index.js";
import DepositOptions from "../../basket/components/DepositOptions.jsx";
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {Select} from "../../../../components/forms/formStyles.js";
import {toast} from "react-toastify";
import {HowManyPeople} from "../../components/HowManyPeople/HowManyPeople.jsx";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    text-align: center;
    gap: 10px;
    max-width: 100vw;
    //background-color: var(--accent-colour);
    
`

const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--text-colour);
    padding-bottom: 20px;
    text-align: left;
    
    
    h3 {
        text-align: center;
        color: var(--accent-colour);
        background: var(--accent-colour);
        font-size: 1.2rem;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
`

const DepositContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;

`


export const Product = ({ product, callToAction }) => {
    if (!product) return null;

    const { event } = useEvent();
    const { addToBasket, selectedAddons } = useEcommerce();
    const { closeDrawer, openDrawer } = useDrawer();
    const { addDependenciesToBasket } = useProduct();

    const [paymentOption, setPaymentOption] = useState('full');
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [numberOfPeople, setNumberOfPeople] = useState(2);

    const hasCatalogue = product?.catalogues?.length > 0;

    useEffect(() => {
        product.addons = selectedAddons;
    }, [selectedAddons]);

    useEffect(() => {
        handleNumberOfPeople();
    }, [numberOfPeople]);

    const openBasket = () => openDrawer(<Basket event={event} />, 'Basket', innerWidth > 768 ? 'right' : 'bottom');

    const handleClick = () => {

        if (hasCatalogue) {
            if (Number(selectedMenus?.length) !== Number(numberOfPeople)) {
                toast.info('Please select a menu option for each person.');

                return;
            }

            product.selectedMenus = selectedMenus; // Assuming the backend can handle this structure
        }

        product.paymentOption = paymentOption;
        product.numberOfGuests = numberOfPeople;
        product.quantity = numberOfPeople ?? 1
        addToBasket(product, numberOfPeople);
        addDependenciesToBasket(product, 7);
        closeDrawer();
        openBasket();
    };

    const handleNumberOfPeople = () => {
        product.priceIncOptions = 50 * numberOfPeople;
        product.depositAmountIncOptions = 25 * numberOfPeople;
        product.sub_total = product.price * numberOfPeople;
    };

    const callToActionText = callToAction?.text ?? 'Add To Basket';
    const callToActionFn = () => {
        (callToAction?.function ?? handleClick)();
    };

    return (
        <Container>



            {product.description && (
                <ProductDescription
                    dangerouslySetInnerHTML={{ __html: product.description }}
                />
            )}

            {  product.category === 'tableBooking' && (
                <HowManyPeople product={product} setNumberOfPeople={setNumberOfPeople} style={{marginBottom: '20px'}} />
            )}


            {hasCatalogue && (
                <>

                    <LinkedCatalogue
                    product={product}
                    selectedMenus={selectedMenus}
                    setSelectedMenus={setSelectedMenus}
                    numberOfPeople={numberOfPeople}
                />

                    <Paragraph>{selectedMenus.length} / {numberOfPeople}</Paragraph>

                </>

            )}

            <Addons product={product} setNumberOfPeople={setNumberOfPeople} numberOfPeople={numberOfPeople} />

            <ButtonComponent
                variant={'solid'}
                text={callToActionText}
                onClick={callToActionFn}
            />
        </Container>
    );
};









Product.propTypes = {
    product: object
}