import React from 'react'
import { useEcommerce } from '../js/context/EcommerceContext';
import { useHandleClickCheckout } from '../js/hooks';
import { BasketContainer, Header } from './styles/basket.styles';
import {Divider} from '../../../components/ui/Divider';
import { BasketFees, BasketItems } from './components';
import { InfoItemComponent } from '../../../components/ui/InfoItemComponent';
import { convertToCurrency } from '../../../js/helpers/helpers';
import { ButtonComponent } from '../../../components/ui/buttons';
import { useDrawer } from '../../../js/hooks/context/DrawerContext';
import {Paragraph} from "../../../components/ui/typography/index.js";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {useEvent} from "../../events/js/hooks/index.js";

function Basket({
    title,
    display,
}) {

const { event } = useEvent();
const {
    total,
    basket,
    setBasket,
    dueToday,
    groupedFees
} = useEcommerce();


const {
    checkoutLoading,
    handleClickCheckout,
} = useHandleClickCheckout(event, event?.business);

const { anchor } = useDrawer();
// const { event } = useEvent();

let style = {};

if(anchor !== 'top' || anchor !== 'bottom') {
    style.backgroundColor = 'transparent'
}


if(basket?.orderItems?.length === 0) {
    return <Paragraph>No items in basket</Paragraph>
}

const amountDueToday = dueToday()
const totalAmountDue = total();
console.log('amountDueToday', amountDueToday)


  return (
    <BasketContainer display={display || 'flex'} style={style} id='inline-basket'>
        <DividerWithText text={'Items'} />

        <BasketItems />

        { groupedFees().length > 0 && (
            <>
                <DividerWithText text={'Fees'} />
                <BasketFees />
            </>
        )}

        <DividerWithText text={'Total'} />
        <InfoItemComponent><p>Total</p>{convertToCurrency(totalAmountDue)}</InfoItemComponent>
        { amountDueToday !== totalAmountDue && <InfoItemComponent><p>Due Today</p>{convertToCurrency(amountDueToday)}</InfoItemComponent> }
        { amountDueToday !== totalAmountDue && (
            <Paragraph>Remaining balance of {convertToCurrency(totalAmountDue - amountDueToday)} required 2 weeks before the event.</Paragraph>
        ) }





        <br />

        <ButtonComponent
        variant={'solid'}
        isLoading={checkoutLoading}
        text='CHECKOUT'
        onClick={() => handleClickCheckout(event, event?.business)}
        />

    </BasketContainer>
  )
}

export default Basket