import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Section } from '../../../layout/components/LayoutSection'
import { TicketList } from '../components/TicketList'
import { TicketItem } from '../components/TicketItem'
import { ImpactTitle, InstructionTitle, Paragraph } from '../../../components/ui/typography'
import { ProductClickProvider } from '../../ecommerce/products/hooks'
import { Form } from '../../../components/forms/formStyles'
import { TextInput } from '../../../components/forms'
import { ButtonComponent } from '../../../components/ui/buttons'
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {ProductList} from "../../ecommerce/products/components/index.js";
import Dates from "../../bookings/components/Dates.jsx";
import {scrollToElement} from "../../../js/Helper.js";
import {useEcommerce} from "../../ecommerce/js/context/EcommerceContext.jsx";
import {useEvent} from "../js/hooks/index.js";
import {getBookingId} from "../../bookings/js/utils/storage.js";
import {makeRequest} from "../../../services/axios.js";
import {LoadingAnimationComponent} from "../../../components/ui/LoadingAnimationComponent.jsx";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {filterProductsByCategory} from "../js/utils.js";
import {PRODUCT_TABLE, PRODUCT_TICKET} from "../js/constants.js";

const ToggleButtonContainer = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    padding-bottom: 30px;
    border-radius: var(--border-radius-default);
    /* background-color: var(--accent-colour); */
`
const TitleContainer = styled.div``
const Title = styled.p`
    font-size: 1.3rem;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.33rem;
`
const SubtitleContainer = styled.div``
const SubTitle = styled.p``



function TicketSection({tickets, tables, event}) {

    const [displayTickets, setDisplayTickets] = useState(tables.length === 0);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [times, setTimes] = React.useState(null);
    const [selectedTime, setSelectedTime] = React.useState(null);
    const [tablesForSpecificDate, setTablesForSpecificDate] = React.useState([])
    const [ticketsForSpecificDate, setTicketsForSpecificDate] = React.useState([])
    const [productsByDate, setProductsByDate] = React.useState(null);
    // const { event } = useEvent();
    const { setTime } = useEcommerce();



    useEffect(() => {
        const time = event.eventDates.find(date => date.id === getBookingId().timeId);

        if(time) {
            setSelectedDate(time);
            setSelectedTime(time);
        }
    }, [])

    useEffect(() => {
        if(selectedTime) {
            scrollToElement('tickets', 'smooth')
        }

        setTime(selectedTime)
    })

    useEffect(() => {

        if(!selectedTime) return;

        makeRequest('GET', `products/datetime/${selectedTime.id}`, null, null)
            .then((res) => {
                console.log('res', res.data)
                setTicketsForSpecificDate(filterProductsByCategory(res.data, PRODUCT_TICKET))
                setTablesForSpecificDate(filterProductsByCategory(res.data, PRODUCT_TABLE))
                setProductsByDate(res.data)
            })

    }, [selectedTime])


    // if(!tickets || tickets.length === 0) return;

    let hasTables;
    let hasTickets;
    let hasTablesAndTickets;
    let hasValidDates;
    let displayToggle;

    tables = tables.sort((a, b) => {
        // First, compare by minSpend
        if (a.minSpend !== b.minSpend) {
            return a.minSpend - b.minSpend;
        } else {
            // If minSpend values are equal, compare by name
            return a.name.localeCompare(b.name);
        }
    });


    hasTables = tables?.length > 0;
    hasTickets = tickets?.length > 0;
    hasTablesAndTickets = hasTables && hasTickets;
    hasValidDates = !event?.eventDates && tablesForSpecificDate?.length > 0;


    const data = displayTickets === true ? tickets : tables;

    const displaying = displayTickets === true ? 'tickets' : 'tables'
    const toggleName = displayTickets === true ? 'tables' : 'tickets'

    if(event.eventDates.length > 0 && !selectedTime && !selectedDate) {
        displayToggle = false;
    } else if (event.eventDates.length > 0 && selectedTime && selectedTime) {
        displayToggle = true;
    } else displayToggle = event.eventDates.length === 0 && hasTablesAndTickets;


    const style = {
        background: 'var(--ticket-section-bg-colour)',
        padding: '120px 20px',
        paddingBottom: '200px',
        minHeight: 'fit-content'
    }

  return (
    <Section
    id="tickets"
    height={tickets.length > 0 ? 'fit-content' : '50vh'}
    justify="center"
    clip={'true'}
    style={style}
    >

        {
            !hasTickets && !hasTables? (
                <Form >
                    <Paragraph>Get notified when tickets go on sale! </Paragraph>
                    <TextInput name={'text'} placeholder={'First Name'} />
                    <TextInput name={'email'} placeholder={'Email'} />
                    <ButtonComponent variant={'outlined'} text={'Notify Me'} />
                </Form>
            ): (
                <>
                    {   displayToggle &&
                        <ToggleButtonContainer>
                            <TitleContainer>
                                <ImpactTitle main={displaying}/>
                            </TitleContainer>
                        </ToggleButtonContainer>
                    }


                    {/*
                        Quick solution for displaying the table menu next to the tables
                        done just for 101s event.

                        TODO - implement tis properly
                    */}

                    {/*{!displayTickets && event.id === 24 && <ImageComponent style={{marginBottom: '30px', maxWidth: '400px'}} src={'https://media.nctrnl.app/images/101-table-menu-5.jpg'} /> }*/}





                    {event.eventDates &&
                        <Dates
                            dates={event.eventDates}
                            setSelectedDate={setSelectedDate}
                            selectedDate={selectedDate}
                            setTimes={setTimes}
                            times={times}
                            setSelectedTime={setSelectedTime}
                            selectedTime={selectedTime}
                            setProductsByDate={setProductsByDate}
                        />}

                    {displayToggle &&
                        <InstructionTitle
                            title={`Tap for ${toggleName}`}
                            style={{marginBottom:'-35px', zIndex:0, cursor:'pointer', textTransform:'uppercase', backgroundColor: 'white', color: 'black'}}
                            onClick={() => setDisplayTickets(displayTickets === false)}
                        />
                    }

                    {(event.eventDates.length === 0 || selectedTime) && (
                        <>
                            {/* If the event has multiple dates then display tables and tickets based on dates*/}
                            {/* others just display the normal list of tickets */}

                            <ProductList data={selectedTime ? displaying === 'tables' ? tablesForSpecificDate : ticketsForSpecificDate : data} />
                            {/*{ isLoading && <LoadingAnimationComponent /> }*/}
                        </>
                    )}



                </>
            )
        }

     

    </Section>  )
}

export default TicketSection