import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import React, {useEffect} from "react";
import {Select} from "../../basket/styles/basket.styles.js";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {InstructionTitle} from "../../../../components/ui/typography/index.js";

export const HowManyPeople = ({ product, setNumberOfPeople, style }) => {

    if(!product) return;

    const { updateOrderItemValue } = useEcommerce();

    useEffect(() => {
        updateOrderItemValue(product.id, 'quantity', 2);
    }, [])

    const handleNumberOfPeople = (people, product) => {
        product.options = { ...product.options, numberOfGuests: Number(people) }
        setNumberOfPeople(people)
        updateOrderItemValue(product.id, 'quantity', people);
    }


    const options = [];

    for (let i = 2; i <= 10; i++) {
        options.push(<option label={i + ' People'} key={i} value={i}>{i}</option>);
    }

    const customStyle = {
        background: 'rgba(0,0,0,0.08)',
        padding: '10px',
        borderRadius: 'var(--border-radius-default)',
        border: 'solid 1px var(--accent-colour)',
        width: '100%',
        minHeight: '50px',
        height: '40px',
        ...style
    }

    return (
        <>
            {/*<DividerWithText text={'Number of Guests'}/>*/}
            <InstructionTitle title={'Number of Guests'} style={{marginBottom: -10}}/>
            <Select
                aria-placeholder={'How many people?'}
                onChange={(e) => handleNumberOfPeople(e.target.value, product)}
                value={product?.options?.numberOfGuests}
                style={customStyle}
            >
                {options}
            </Select>
        </>

    )
}