import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios';
import {PageContainer} from '../styles/checkoutPage.styles';
import {Loading} from '../../../../components/ui/Loading';
import {useCheckout} from '../../js/context/CheckoutContext';
import {API_URL, scrollToElement} from '../../../../js/Helper';
import {ConvertBase64IdFromParams, useUrlParams} from '../../../../js/hooks/useParam';
import {useEcommerce} from '../../js/context/EcommerceContext';
import {AuthContext} from '../../../authentication';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {useEvent} from "../../../events/js/hooks/index.js";
import OrderSummarySection from "../components/OrderSummarySection.jsx";
import QuestionsSection from "../components/QuestionsSection.jsx";
import PaymentSection from "../components/PaymentSection.jsx";
import {updateTheme} from "../../../themes/js/utils.js";
import {useApiQuery} from "../../../../hooks/customHooks.js";
import {FadedBgImage} from "../../../../components/ui/FadedBgImage.jsx";

function CheckoutPage() {


  const [fetchedData, setFetchedData] = useState();

  const { header } = useContext(AuthContext);

  const {  setConfig, config } = useEcommerce();
  const { setEvent, event } = useEvent();
  const params =  useUrlParams();
  const { showErrorPage, showErrorToast } = useHandleError()
  const id = ConvertBase64IdFromParams(params.id);

  const {
    checkout,
    setCheckout,
    setTotal,
    setFees,
    setStripe,
    setQuestions,
    checkoutQuestions
  } = useCheckout();

  useEffect(() => {
    const eventListener = async (event) => {
      const req = axios.put(API_URL + '/checkout/' + id, {
            status: 'payment_initiated',
            user_id: event.detail.user.id
          },
          {
            headers: header()
          })
          .then((req) => {

            setTimeout(() => {
              scrollToElement('payment-form')
            }, 500)
          })
          .catch((error) => {
            showErrorToast(error)
            console.error('Error in event listener', error);
          })
    };

    // Add the event listener
    window.addEventListener('loggedIn', eventListener);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('loggedIn', eventListener);
    };
  }, [])



  try {

    // const {data, isLoading, error} = getCheckout(id);

    useApiQuery(`${API_URL}/checkout/${id}`, (data) => {

        console.log('data', data)

        const formatFees = data.checkout.order_items.flatMap(orderItem => {
            const formattedFees = [];

            // Collect fees for the main order item
            if (orderItem.order_fees && orderItem.order_fees.length > 0) {
                orderItem.order_fees.forEach(fee => {
                    formattedFees.push({
                        fee_type: fee.product_fee.name, // Use order_item_id as fee_type
                        amount: fee.amount || 0 // Default to 0 if amount is not defined
                    });
                });
            }

            // Collect fees for the addons
            if (orderItem.addons && orderItem.addons.length > 0) {
                orderItem.addons.forEach(addon => {
                    if (addon.order_fees && addon.order_fees.length > 0) {
                        addon.order_fees.forEach(fee => {
                            formattedFees.push({
                                fee_type: fee.product_fee.name, // Use addon id as fee_type
                                amount: fee.amount || 0 // Default to 0 if amount is not defined
                            });
                        });
                    }
                });
            }

            return formattedFees;
        });

        console.log('FormattedFees', formatFees)


      if(data?.status === 'success') {
            if(data?.checkout) setCheckout(data?.checkout)
            if(data?.fees) setFees(formatFees);
            if(data?.total) setTotal(data?.totals);
            if(data?.stripe) setStripe(data?.stripe);
            if(data?.event) setEvent(data?.event);
            if(data?.checkoutQuestions) setQuestions(data?.checkoutQuestions);
            if(data?.config) setConfig(data.config);
            updateTheme(data?.event?.theme)
      }
    })

    //
    // if(isLoading) return <Loading/>
    //
    // if(error) {
    //   return showErrorPage(error);
    // }
    //
    // if(!data) return <Loading />
    //
    //   if(data?.status === 'success') {
    //
    //     if(data?.checkout) setCheckout(data?.checkout)
    //     if(data?.fees) setFees(data?.fees);
    //     if(data?.total) setTotal(data?.totals);
    //     if(data?.stripe) setStripe(data?.stripe);
    //     if(data?.event) setEvent(data?.event);
    //     if(data?.checkoutQuestions) setQuestions(data?.checkoutQuestions);
    //     if(data?.config) setConfig(data.config);
    //     updateTheme(data?.event?.theme)
    //
    //   }



    // if(!checkout) return;
    //
    // if(checkout.status === 'expired') {
    //   return 'checkout expired';
    // }
    
  } catch (e) {
    console.log('error on checkout page load', e)
    showErrorPage(e.message)
  }


  if(!checkout) return <Loading />
  // if(checkout.status === 'awaiting_payment_confirmation') {
  //   return 'We are waiting for payment confirmation';
  // }

  return (
    <PageContainer>
      { innerWidth < 1024 && <FadedBgImage image={`${event?.image?.cdnUrl}`} />}
      <OrderSummarySection />
        { config?.hasQuestions && <QuestionsSection /> }
      <PaymentSection />
    </PageContainer>
  )
}

export default CheckoutPage