import React from 'react';
import { useDrawer } from "../../../../js/hooks/context/DrawerContext.jsx";
import { useApiQuery } from "../../../../hooks/customHooks.js";
import { useEcommerce } from "../../js/context/EcommerceContext.jsx";
import { IoAddSharp, IoInformationCircleSharp } from "react-icons/io5";
import { Product } from "./Product.jsx";
import styled from "styled-components";
import {InstructionTitle, Paragraph} from "../../../../components/ui/typography/index.js";

// Styled components for horizontal scroll
const CatalogueSectionContainer = styled.section`
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    display: flex;

    gap: 10px; /* Space between guest catalogues */
    color: var(--text-colour);
    

    /* Hide scrollbar for WebKit browsers */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for Internet Explorer and Edge */
    -ms-overflow-style: none;
`;
const CatalogueToggleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border-radius: var(--border-radius-medium);
    // border: solid 1px ${props => props.isSelected ? 'var(--success-colour)' : 'var(--accent-colour)'};
    //background-color: cadetblue;
    padding: 10px;
    width: 290px;
    min-width: 290px; /* Ensure each container has a minimum width for horizontal scrolling */
    max-width: 300px;
    flex-shrink: 0; /* Prevent shrinking */
`;


const CatalogueContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px ${props => props.isSelected ? 'var(--success-colour)' : 'var(--accent-colour)'};
    border-radius: var(--border-radius-medium);
    //background-color: red;
    //gap: 1px;
    width: 100%;
    //padding: 10px 0px;
    overflow: hidden; /* Ensure it hides content when collapsed */
`;

const CatalogueProductContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    min-width: 100%;
    width: 100%;
    padding: 10px;
    //border-radius: var(--border-radius-small);
    text-align: left;
    border-bottom: solid 1px rgba(100,100,100,0.2);
    background-color: rgba(0, 0, 0, 0.1);
`;

const CatalogueProductName = styled.p`
    color: var(--text-colour);
    width: 75%;
    text-align: left;
`

export const LinkedCatalogue = ({ product, setSelectedMenus, selectedMenus, numberOfPeople }) => {
    const { openDrawer, closeDrawer } = useDrawer();
    const { data, isLoading, error } = useApiQuery('menu/1');
    const { addToBasket } = useEcommerce();

    if (error || isLoading) return null;

    const catalogue = data?.data;

    const handleSelectOption = (selectedMenu, personIndex) => {
        // Create a copy of the selectedMenus array
        const updatedMenus = [...selectedMenus];
        console.log('seklec', selectedMenus)

        // Update the specific index with the selected menu
        selectedMenu.guestIndexNumber = personIndex;
        updatedMenus[personIndex] = selectedMenu;

        console.log('updated', updatedMenus)

        // Filter out any falsy values (null, undefined, etc.) and only keep truthy values
        const filteredMenus = updatedMenus.filter(Boolean);

        // Update the state with the filtered array
        setSelectedMenus(filteredMenus);
    };



    const callToAction = {
        text: 'Choose Me',
        function: (selectedMenu, personIndex) => {
            addToBasket(selectedMenu, 1);
            handleSelectOption(selectedMenu, personIndex);
            closeDrawer();
        }
    };

    return (
        <CatalogueSectionContainer id={'cat-section-container'}>
            {Array.from({ length: numberOfPeople }).map((_, personIndex) =>  {

                console.log('index', personIndex)

                const displayIndex = personIndex + 1;
                return (

                <CatalogueToggleContainer id={'cat-toggle-container'}
                    key={personIndex}
                    // isSelected={selectedMenus[numberOfPeople - 1 - personIndex] !== undefined} // Border color based on selection
                >
                    <InstructionTitle style={{marginBottom: 0}} title={`Food for Guest ${displayIndex}`}/>

                    <CatalogueContainer id={'cat-container'}>
                        {catalogue.products.map((productItem, menuIndex) => {
                            const { product: menuProduct } = productItem;
                            const isSelected = selectedMenus && selectedMenus[personIndex] === menuProduct;
                            console.log('selectedMenus', selectedMenus[personIndex])
                            console.log('menuProduct', menuProduct)

                            return (
                                <CatalogueProductContainer
                                    id={'cat-product-container'}
                                    key={menuIndex}
                                    style={{
                                        backgroundColor: isSelected ? 'var(--accent-colour)' : ''
                                    }}
                                >
                                    <IoInformationCircleSharp
                                        color={'var(--info-colour)'}
                                        size={20}
                                        onClick={() => openDrawer(
                                            <Product product={menuProduct}
                                                     callToAction={(menu) => callToAction.function(menu, personIndex)} />,
                                            menuProduct.name
                                        )}
                                    />
                                    <CatalogueProductName>{menuProduct.name}</CatalogueProductName>
                                    <IoAddSharp
                                        color={'var(--success-colour)'}
                                        style={{
                                            opacity: isSelected ? 0 : 1
                                        }}
                                        size={20}
                                        onClick={() => handleSelectOption(menuProduct, personIndex)}
                                    />
                                </CatalogueProductContainer>
                            );
                        })}
                    </CatalogueContainer>
                </CatalogueToggleContainer>
            )})}
        </CatalogueSectionContainer>
    );
};
