import {productPrice} from "../../../products/js/utils/productUtils.js";

const orderItemSubTotal = (orderItem) => {
     return orderItem.quantity * orderItem.price ?? 0;
 }

 const orderItemFullPrice = (orderItem) => {
     return orderItem.quantity * orderItem.price;
 }


export {
    orderItemSubTotal,
    orderItemFullPrice
}
